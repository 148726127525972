<template>
  <div>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
        xl="3"
        class="mt-5"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-title
            primary-title
            class="justify-center"
          >
            <img
              :src="$_get_image('login.png')"
              width="80"
              class="d-block"
            >
            <div class="col-12 text-center">
              {{ $t('Login to your account') }}
            </div>
          </v-card-title>
          <validation-observer
            ref="theForm"
            v-slot="{ handleSubmit, reset }"
          >
            <form
              autocomplete="off"
              @submit.prevent="handleSubmit(submit)"
              @reset.prevent="reset"
            >
              <v-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Username')"
                  rules="required"
                >
                  <v-text-field
                    v-model="form.username"
                    append-icon="mdil-account"
                    :label="$t('Username')"
                    outlined
                    dense
                    :error-messages="errors"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Password')"
                  rules="required"
                >
                  <v-text-field
                    v-model="form.password"
                    :label="$t('Password')"
                    :append-icon="show1 ? 'mdil-eye' : 'mdil-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    outlined
                    dense
                    :error-messages="errors"
                    @click:append="show1 = !show1"
                  />
                </validation-provider>
              </v-card-text>

              <v-card-actions class="pb-4">
                <v-spacer />
                <v-btn
                  type="submit"
                  color="primary"
                  depressed
                  style="width: 100%"
                >
                  <v-icon
                    left
                    class="mdil-24px"
                  >
                    mdil-login
                  </v-icon>
                  {{ $t('Login') }}
                </v-btn>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      show1: false,
      form: {
        username: '',
        password: ''
      },
    }
  },

  computed: {
    ...mapGetters(['isLoggedIn']),
  },

  beforeMount() {
    if (this.isLoggedIn) {
      this.$router.push({ name: 'my-company' })
    }
  },

  methods: {
    ...mapActions([
      'clientLogin',
      'getAuthUserData',
      'getAuthUserPermission',
      'getAttachmentTypesList',
    ]),

    submit() {
      this.clientLogin(this.form).then(() => {
        this.getAuthUserData()
        this.$router.push({ name: 'my-company' })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>